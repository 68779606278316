import React, { useEffect } from 'react';
import { useCallback, useState } from 'react';
import { Container, Menu, Button, Header, Icon, Divider, Card, Message, Modal } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import Web3 from 'web3';
import contractABI1 from './utils/abi_contract_1';
import contractAddress from './utils/contract1';
import contractABI2 from './utils/abi_contract_2';
import contractAddress1 from './utils/contract2';
import sha3 from 'js-sha3';

function App() {
  const [fileName, setFilename] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [wallet, setWallet] = useState('0x0');
  const [hash, setHash] = useState("");
  const [docCount, setdocCount] = useState(null);
  const [owner, setOwner] = useState("");
  const [addTimestamp, setAddTimestamp] = useState(null);
  const [verified, setVerified] = useState(false);
  const [verifyTimestamp, setVerifyTimestamp] = useState(null);
  const [signer, setSigner] = useState("");
  const [message, setMessage] = useState("");
  const [state, dispatch] = React.useReducer(boardingReducer, {
    open: false,
    dimmer: undefined,
  });
  const { open, dimmer } = state;
 
  useEffect(() => {
    document.title = 'Blockchain Digital Verify';
    dispatch({ type: 'OPEN_MODAL' });

    if (!window.ethereum) {
      // Nothing to do here... no ethereum provider found
      return;
    };
    const accountWasChanged = (accounts) => {
      if(accounts.length > 0){
        setWallet(accounts[0]);
        setIsConnected(true);
      }else{
        setWallet('0x0');
        setIsConnected(false);
      }
      console.log('accountWasChanged');
    };
    const getAndSetAccount = async () => {
      try {
        const changedAccounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        if(changedAccounts.length > 0){
          setWallet(changedAccounts[0]);
          setIsConnected(true);
        };
        console.log('getAndSetAccount');
      } catch (e) {
        console.log('Error');
        return;
      }
    };
    const clearAccount = () => {
      setWallet('0x0');
      setIsConnected(false);
      console.log('clearAccount');
    };
    window.ethereum.on('accountsChanged', accountWasChanged);
    window.ethereum.on('connect', getAndSetAccount);
    window.ethereum.on('disconnect', clearAccount);
    window.ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
      console.log('accounts', accounts);
      if(accounts.length > 0){
        setWallet(accounts[0]);
        setIsConnected(true);
      };
      // No need to set account here, it will be set by the event listener
    }, error => {
      // Handle any UI for errors here, e.g. network error, rejected request, etc.
      // Set state as needed 
    });
    return () => {
      // Return function of a non-async useEffect will clean up on component leaving screen, or from re-reneder to due dependency change
      window.ethereum.removeListener('accountsChanged', accountWasChanged);
      window.ethereum.removeListener('connect', getAndSetAccount);
      window.ethereum.removeListener('disconnect', clearAccount);
    };
  }, []);

  function boardingReducer(state, action) {
    switch (action.type) {
      case 'OPEN_MODAL':
        return { open: true, dimmer: action.dimmer }
      case 'CLOSE_MODAL':
        return { open: false }
      default:
        throw new Error()
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        console.log(e.target.result );
        setOwner("");
        setAddTimestamp(null);
        setVerified(false);
        setVerifyTimestamp(null);
        setSigner("");
      };

      reader.onloadend = async () => {
        setFilename(file.path);
        setFileSize(file.size);
        const fileData = reader.result;
        const hashHex = sha3.keccak256(fileData);
        const hashBytes32 = "0x" + hashHex;
        setHash(hashBytes32);
        console.log("File Hash:", hashBytes32);
      }
      reader.readAsArrayBuffer(file);      

      return file;
    });
  }, []);
  
  function convertEpochToSpecificTimezone(timeEpoch, offset){
    console.log(timeEpoch);
    var d = new Date(timeEpoch);
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000);  //This converts to UTC 00:00
    var nd = new Date(utc + (3600000*offset));
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return nd.toLocaleString('en-US', options);
  };

  function connectToWallet() {
    window.ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
      console.log('accounts', accounts);
      if(accounts.length > 0){
        setWallet(accounts[0]);
        setIsConnected(true);
      };      
      // No need to set account here, it will be set by the event listener
    }, error => {
      // Handle any UI for errors here, e.g. network error, rejected request, etc.
      // Set state as needed 
    });
  };

  async function disconnectFromWallet() {
    setIsConnected(false);
  };

  async function handleAddDocument(documentHash){
    
    // eslint-disable-next-line
    const web3 = new Web3("https://testnet.blockdify.com");
    // eslint-disable-next-line
    const contract = await new web3.eth.Contract(contractABI1, contractAddress);

    contract.methods.addDocument(documentHash.hash).send({from: wallet, gas: '5000000'})
    .then(receipt => {
      console.log(receipt);
      setMessage(JSON.stringify(receipt));
    })
    .catch(error => {
      console.log(error);
      setMessage(error);
    });
  };

  async function handleVerifyDocument(documentHash){
    // eslint-disable-next-line
    const web3 = new Web3("https://testnet.blockdify.com");
    // eslint-disable-next-line
    const contract = await new web3.eth.Contract(contractABI1, contractAddress);

    contract.methods.verifyDocument(documentHash.hash).send({from: wallet, gas: '5000000'})
    .then(receipt => {
        console.log(receipt);
        setMessage(JSON.stringify(receipt));
    })
    .catch(error => {
        console.log(error);
        setMessage(error);
    });
  };
  
  async function handleDocumentCount(){
    // eslint-disable-next-line
    const web3 = new Web3("https://testnet.blockdify.com");
    // eslint-disable-next-line
    const contract = await new web3.eth.Contract(contractABI1, contractAddress);

    contract.methods.getNumberOfDocuments().call().then(function(result) {
      setdocCount(result);
      setMessage("Number of documents on chain: " + result);
    });
  };


  async function handleDocumentDetails(documentHash){
    // eslint-disable-next-line
    const web3 = new Web3("https://testnet.blockdify.com");
    // eslint-disable-next-line
    const contract = await new web3.eth.Contract(contractABI1, contractAddress);

    contract.methods.getDocument(documentHash.hash).call().then(function(result) {
      setOwner(result[1]);
      setAddTimestamp(result[2]);
      setVerified(result[3]);
      setVerifyTimestamp(result[4]);
      if(result[1] === '0x0000000000000000000000000000000000000000'){
        setMessage("No record available for this document hash in the blockchain");
      }else{
        if(result[4] > 0)
        setMessage("Owner: " + result[1] + "<br/>Add To Blockchain Timestamp: " + convertEpochToSpecificTimezone(parseInt(result[2])*1000, +8) + "<br/>Verified Status: " + result[3] + "<br/>Verify Timestamp: " + convertEpochToSpecificTimezone(parseInt(result[4])*1000, +8));
        else
          setMessage("Owner: " + result[1] + "<br/>Add To Blockchain Timestamp: " + convertEpochToSpecificTimezone(parseInt(result[2])*1000, +8) + "<br/>Verified Status: " + result[3] + "<br/>Verify Timestamp: n/a");
        };
    });
  };


  async function handleSigning(documentHash){
    // eslint-disable-next-line
    const web3 = new Web3("https://testnet.blockdify.com");
    // eslint-disable-next-line
    const contract = await new web3.eth.Contract(contractABI2, contractAddress1);

    contract.methods.signDocument(documentHash.hash).send({from: wallet, gas: '5000000'})
    .then(receipt => {
      console.log(receipt);
      setMessage(JSON.stringify(receipt));
    })
    .catch(error => {
      console.log(error);
      setMessage(error);
    });
  };


  async function handleGetSigner(documentHash){
    // eslint-disable-next-line
    const web3 = new Web3("https://testnet.blockdify.com");
    // eslint-disable-next-line
    const contract = await new web3.eth.Contract(contractABI2, contractAddress1);

    contract.methods.getSigner(documentHash.hash).call().then(function(result) {
      setSigner(result);
      setMessage("Signer: " + result);
    });
  };

  async function handleDocumentVerify(documentHash){
    // eslint-disable-next-line
    const web3 = new Web3("https://testnet.blockdify.com");
    // eslint-disable-next-line
    const contract = await new web3.eth.Contract(contractABI1, contractAddress);

    contract.methods.getDocument(documentHash.hash).call().then(function(result) {
      setOwner(result[1]);
      setAddTimestamp(result[2]);
      setVerified(result[3]);
      setVerifyTimestamp(result[4]);
      if(result[1] === '0x0000000000000000000000000000000000000000'){
        setMessage("<span style='color:red;font-weight:bold'>This document is not valid or has been tampered!</span> <br/>Please check with this document provider if it has been certified and registered in this blockchain.");
      }else{
        if(result[4] > 0)
          setMessage("<span style='color:green;font-weight:bold'>This document is valid and certified in this blockchain.</span> <br/><br/>Owner: " + result[1] + "<br/>Add To Blockchain Timestamp: " + convertEpochToSpecificTimezone(parseInt(result[2])*1000, +8) + "<br/>Verified Status: " + result[3] + "<br/>Verify Timestamp: " + convertEpochToSpecificTimezone(parseInt(result[4])*1000, +8));
        else
          setMessage("<span style='color:green;font-weight:bold'>This document is valid and certified in this blockchain.</span> <br/><br/>Owner: " + result[1] + "<br/>Add To Blockchain Timestamp: " + convertEpochToSpecificTimezone(parseInt(result[2])*1000, +8) + "<br/>Verified Status: " + result[3] + "<br/>Verify Timestamp: n/a");
        };
    });
  };

  function reset() {
    setHash("");
    setOwner("");
    setAddTimestamp(null);
    setVerified(false);
    setVerifyTimestamp(null);    
    setMessage("");
    setdocCount(null);
  };

  return (
    <Container>
      <Divider hidden></Divider>
      <Menu size="large">
          <Menu.Item>
            <Header as='h2'>
                <Icon name='cube' size="big"/>
                <Header.Content>BLOCKDIFY</Header.Content>
            </Header>
          </Menu.Item>
          <Menu.Item position='right' >
              <Button onClick={connectToWallet} style={{ display: isConnected ? 'none' : 'block' }}>Connect Wallet</Button>
              <Button onClick={disconnectFromWallet} style={{ display: isConnected ? 'block' : 'none' }}>Disconnect Wallet</Button>
          </Menu.Item>
      </Menu>
      <Header as='h2' textAlign='center'>
        <Header.Content>DOCUMENT IDENTIFICATION AND VERIFICATION USING BLOCKCHAIN</Header.Content>
      </Header>    
      <Header as='h3'>Select Required Document</Header>
      <Dropzone onDrop={onDrop} multiple={false}>
        {({getRootProps, getInputProps}) => (
          <div className="container">
            <div
              {...getRootProps({
                className: 'dropzone',
                onClick: event => reset()
              })}
            >
              <input {...getInputProps()} />
              <p>Drag 'n' drop document here, or click to select document</p>
            </div>
          </div>
        )}
      </Dropzone>            
      <Header as='h3' textAlign='center' style={{ display: hash ? 'block' : 'none' }}>
        <Header.Content>
          {fileName}
          <Header.Subheader>Document Size: {fileSize} bytes</Header.Subheader>
        </Header.Content>
      </Header>  
      <Header as='h2' block textAlign='center' style={{ display: hash ? 'block' : 'none', wordWrap: 'break-word' }}>
          {hash}
      </Header>
      <Card fluid style={{ display: hash && isConnected ? 'block' : 'none' }}>
        <Card.Content>
          <Card.Header textAlign='center'>Blockchain Action</Card.Header>
        </Card.Content>
        <Card.Content textAlign='center'>
          <Button onClick={() => handleAddDocument({hash})}>Add Hash</Button>
          <Button onClick={() => handleVerifyDocument({hash})}>Verify Hash</Button>
          <Button onClick={() => handleDocumentCount({hash})}>Document Count</Button>
          <Button onClick={() => handleDocumentDetails({hash})}>Get Details</Button>
          <Button onClick={handleSigning}>Sign</Button>
          <Button onClick={handleGetSigner}>Get Signer</Button>
          <Message size='large' style={{ display: message ? 'block' : 'none',  wordWrap: 'break-word'}}>
            <p dangerouslySetInnerHTML={{__html: message}} />
          </Message> 
        </Card.Content>
      </Card>
      <Container style={{ display: isConnected ? 'none' : 'block', marginTop: '10px' }}>
        <Container textAlign='center' style={{marginBottom: '10px'}}>
          <Message size='large' style={{ display: message ? 'block' : 'none',  wordWrap: 'break-word'}}>
            <p dangerouslySetInnerHTML={{__html: message}} />
          </Message>     
        </Container>
        <Button onClick={() => handleDocumentVerify({hash})}>Verify Document</Button>    
      </Container>
      <Modal
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
      >
        <Modal.Header>GUIDELINE</Modal.Header>
        <Modal.Content>
          <h3 style={{textAlign: 'center'}}>Please connect to MetaMask wallet if you want to login as document provider or authority.
          <br />Document verification can be use without MetaMask wallet.</h3>
          <Divider></Divider>
          <div id="faq">
            <div class="faqTitle">FAQ</div>
            <dl>
              <dt>Do I need ETH to use BLOCKDIFY as document provider or authority?</dt>
              <dd><p>Yes, ETH is required to pay gas fees (which is a couple cents).</p></dd>
              <dt><p>Do you store my documents?</p></dt>
              <dd><p>No, the contents of your document are never stored anywhere.</p></dd>
              <dt><p>Are the contents of my documents safe?</p></dt>
              <dd><p>Yes, only the cryptographic hash of your documents is stored in the blockchain. The hash is calculated locally on your computer and serves as a fingerprint for your document. It is mathematically impossible to reconstruct your document from this hash.</p></dd>

              <dt>Why should I use BLOCKDIFY?
              </dt><dd><p>BLOCKDIFY is implemented using a smart contract on the Ethereum blockchain. When you record a document using BLOCKDIFY, you are guaranteed that this record is immutable. This information cannot be lost, it cannot be modified even by governments and you can always verify this information even if BLOCKDIFY website is down.</p></dd><dd>
              </dd><dt><p>Can I use BLOCKDIFY for contracts or any signed documents?</p>
              </dt><dd>
              <p>Yes, you can scan a copy of the physically signed contract or other documents and save the cryptographic hash it on blockchain. It will also record the document owner wallet address and the signer address so that you can later proved the authenticity and validity of the document.</p>

              <p>When you need to preserve data integrity - The hash (Keccak-256) that is computed maintains a fingerprint of your document. This changes with any modification to the original document.</p>

              <p>When you want the peace of mind that your document record is stored on a decentralized system that prevents any manipulation.</p>

              </dd>

              <dt>Can I delete or undo any document records?</dt>
              <dd><p>No, all records will live on the blockchain forever.</p></dd>
              </dl>
          </div>          
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => dispatch({ type: 'CLOSE_MODAL' })}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>  
    </Container>
  );
};

export default App;
 
